import { render, staticRenderFns } from "./CompanyUserView.vue?vue&type=template&id=2062ff7e&scoped=true&"
import script from "./CompanyUserView.vue?vue&type=script&lang=js&"
export * from "./CompanyUserView.vue?vue&type=script&lang=js&"
import style0 from "./CompanyUserView.vue?vue&type=style&index=0&id=2062ff7e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2062ff7e",
  null
  
)

export default component.exports