// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import { USER_ROLE } from '@/constants/static.json'
import { mixinDate } from '@/constants/mixinDate'

export const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)

export const sortCompare = key => (a, b) => {
  const fieldA = a[key]
  const fieldB = b[key]

  let comparison = 0
  if (fieldA > fieldB) {
    comparison = 1
  } else if (fieldA < fieldB) {
    comparison = -1
  }
  return comparison
}

export const getRandomInt = (min, max) => {
  if (min > max) {
    const temp = max
    /* eslint-disable no-param-reassign */
    max = min
    min = temp
    /* eslint-enable */
  }

  if (min <= 0) {
    return Math.floor(Math.random() * (max + Math.abs(min) + 1)) + min
  }
  return Math.floor(Math.random() * max) + min
}

export const randomDate = (start, end) => {
  const diff = end.getTime() - start.getTime()
  const newDiff = diff * Math.random()
  const date = new Date(start.getTime() + newDiff)
  return date
}

export const canDeleteGame = data => {
  let result = true
  const today = moment(new Date()).format('YYYY-MM-DD')
  const startDate = moment(data.startdate).format('YYYY-MM-DD')
  if (today >= startDate) {
    result = false
  }
  if (data.isArchive === -1) {
    result = false
  }
  return result
}

export const isTodayOrFutureDate = date => {
  const today = moment(new Date()).format('YYYY-MM-DD')
  const formattedEndDate = moment(date).format('YYYY-MM-DD')
  return today <= formattedEndDate
}

export const isTodayOrPastDate = date => {
  const today = moment(new Date()).format('YYYY-MM-DD')
  const formattedEndDate = moment(date).format('YYYY-MM-DD')
  return today >= formattedEndDate
}

export const canEditDeleteGame = data => {
  let result = true
  const today = moment(new Date()).format('YYYY-MM-DD')
  const endDate = moment(data.enddate).format('YYYY-MM-DD')
  if (today > endDate) {
    result = false
  }
  if (data.isArchive === -1) {
    result = false
  }
  return result
}

export const countingObject = data => {
  let usersCount = 0; let groupCount = 0; const users = []
  const playGroup = data.filter(
    group => (group.groupcomplevel !== -2),
  )
  groupCount = playGroup.length
  playGroup.forEach(group => {
    group.users.forEach(user => {
      // eslint-disable-next-line no-plusplus
      usersCount++
      users.push(user)
    })
  })
  return { usersCount, groupCount, users }
}

export const mapTeamRoleToVariant = role => {
  switch (role) {
    case -2:
      return {
        id: 1,
        name: 'Coach',
        description_1: 'Coaches',
        description_2: 'Coaching Team',
        variant: 'success',
        Icon: 'UserIcon',
      }
    case 0:
      return {
        id: 2,
        name: 'Player',
        description_1: 'Players',
        description_2: 'Team',
        variant: 'warning',
        Icon: 'UserIcon',
      }
    default:
      return {
        id: 0,
        name: '',
        variant: '',
        Icon: '',
      }
  }
}

export const mapFrequencyToVariant = frequency => {
  switch (frequency) {
    case 'one-time':
      return 'light-info'
    case 'weekly':
      return 'light-success'
    case '4-weeks':
      return 'light-primary'
    case '12-weeks':
      return 'light-warning'
    case '26-weeks':
      return 'light-primary'
    case '52-weeks':
      return 'light-danger'
    default:
      return 'light-secondary'
  }
}

export const mapTagToVariant = tag => {
  switch (tag) {
    case 'B2B Sales':
      return {
        id: 1,
        name: 'B2B Sales',
        description: 'Focus on business-to-business sales',
        variant: 'light-primary',
        Icon: 'StarIcon',
        svg: 'toolbox.svg',
      }
    case 'Social Selling':
      return {
        id: 2,
        name: 'Social Selling',
        description: 'Using social media to drive sales',
        variant: 'light-success',
        Icon: 'LinkedinIcon',
        svg: 'parachute.svg',
      }
    case 'Leadership':
      return {
        id: 3,
        name: 'Leadership',
        description: 'Leadership and management',
        variant: 'light-warning',
        Icon: 'SunIcon',
        svg: 'speaker.svg',
      }
    case 'Retail':
      return {
        id: 4,
        name: 'Retail',
        description: 'Managing retail and store sales',
        variant: 'light-secondary',
        Icon: 'ShoppingCartIcon',
        svg: 'star.svg',
      }
    case 'Consulting':
      return {
        id: 5,
        name: 'Consulting',
        description: 'Providing consulting services',
        variant: 'light-dark',
        Icon: 'TrelloIcon',
        svg: 'book.svg',
      }
    case 'Sustainability':
      return {
        id: 6,
        name: 'Sustainability',
        description: 'Focusing on sustainable practices',
        variant: 'light-info',
        Icon: 'SunIcon',
        svg: 'rocket.svg',
      }
    default:
      return {
        id: 0,
        name: '',
        description: '',
        variant: 'light-danger',
        Icon: 'FileIcon',
        svg: 'default.svg',
      }
  }
}

export const mapStatusToVariant = status => {
  switch (status) {
    case 'draft':
      return 'light-info'
    case 'Open':
      return 'light-success'
    case 'Closed':
      return 'light-secondary'
    default:
      return 'light-danger'
  }
}

export const resolveConstantStatusVariant = data => {
  const mixin = mixinDate.methods
  const today = mixin.formatDateDash(new Date())
  const startDate = mixin.formatDateDash(data.start)
  const endDate = mixin.formatDateDash(data.end)
  if (data.record_status === 0) return { variant: 'info', text: 'unpublished' }
  if (today < startDate) return { variant: 'warning', text: 'unstarted' }
  if (today >= startDate && today <= endDate) return { variant: 'success', text: 'ongoing' }
  if (today > endDate) return { variant: 'secondary', text: 'finish' }
  return { variant: 'primary', text: '' }
}

export const resolveUserStatusVariant = status => {
  switch (true) {
    case status === 0:
      return { id: 1, name: 'Pending', variant: 'warning' }
    case status > 0:
      return { id: 2, name: 'Active', variant: 'success' }
    case status < 0:
      return { id: 3, name: 'Inactive', variant: 'secondary' }
    default:
      return { id: 0, name: 'Error', variant: 'danger' }
  }
}

export const resolveUploadUserStatusVariant = status => {
  switch (true) {
    case status === 1:
      return { id: 1, name: 'Pending', variant: 'success' }
    case status === 2:
      return { id: 2, name: 'Completed', variant: 'warning' }
    case status === -5:
      return { id: 3, name: 'Pending', variant: 'info' }
    default:
      return { id: 0, name: 'Error', variant: 'danger' }
  }
}

export const mapUserRoleToVariant = role => {
  let result = {}
  switch (role) {
    case USER_ROLE.ADMIN:
      result = {
        id: 1, name: 'Admin', variant: 'primary', Icon: 'UserIcon',
      }
      break
    case USER_ROLE.COMPANY_ADMIN:
      result = {
        id: 2, name: 'Company admin', variant: 'warning', Icon: 'UserIcon',
      }
      break
    case USER_ROLE.PROJECT_ADMIN:
      result = {
        id: 3, name: 'Project admin', variant: 'success', Icon: 'UserIcon',
      }
      break
    case USER_ROLE.PLAYER:
      result = {
        id: 4, name: 'Player', variant: 'info', Icon: 'UserIcon',
      }
      break
    case USER_ROLE.INSPECTOR:
      result = {
        id: 5, name: 'Inspector', variant: 'danger', Icon: 'UserIcon',
      }
      break
    case USER_ROLE.ANALYZER:
      result = {
        id: 6, name: 'Inspector', variant: 'danger', Icon: 'UserIcon',
      }
      break
    default:
      result = {
        id: 0, name: '', variant: '', Icon: '',
      }
      break
  }

  return result
}

export const resolveProjectRole = role => (role
  ? {
    id: 1, name: 'Project Admin', variant: 'success', Icon: 'UserIcon',
  }
  : {
    id: 2, name: 'Member', variant: 'warning', Icon: 'UserIcon',
  })

export const resolveBatchUrl = index => {
  const icons = {
    // eslint-disable-next-line global-require
    1: require('@/assets/images/icons/gold.png'),
    // eslint-disable-next-line global-require
    2: require('@/assets/images/icons/silver.png'),
    // eslint-disable-next-line global-require
    3: require('@/assets/images/icons/bronze.png'),
    // eslint-disable-next-line global-require
    4: require('@/assets/images/icons/place.png'),
  }

  // Default to gold if the index is out of bounds
  const iconPath = icons[index] || icons[1]

  // eslint-disable-next-line global-require, import/no-dynamic-require
  return iconPath
}

export const mapPackageToVariant = packageId => {
  switch (packageId) {
    case 1:
      return {
        id: 1,
        name: 'Free',
        variant: 'primary',
      }
    case 2:
      return {
        id: 2,
        name: '6 Months',
        variant: 'warning',
      }
    case 3:
      return {
        id: 3,
        name: 'Annual',
        variant: 'info',
      }
    case 4:
      return {
        id: 4,
        name: '2 Years',
        variant: 'success',
      }
    default:
      return {
        id: 0,
        name: '',
        variant: '',
      }
  }
}
