<template>
  <section id="dashboard-analytics">

    <b-card
      no-body
      class="card-developer-meetup"
    >
      <b-card-body>
        <!-- metting header -->
        <div class="meetup-header d-flex align-items-center">
          <div class="meetup-day">
            <feather-icon
              icon="LayoutIcon"
              size="34"
              class="mr-50 text-primary"
            />
          </div>
          <div class="my-auto">
            <b-card-title class="mb-25">
              {{ company.name }}
              <b-badge
                :variant="`light-${mapPackageToVariant(company.packageId).variant}`"
              >
                {{ $t('PACKAGE') }} : {{ mapPackageToVariant(company.packageId).name }}
              </b-badge>
            </b-card-title>
          </div>
        </div>
        <!--/ metting header -->
        <!-- start date -->
        <b-media
          no-body
        >
          <b-media-aside
            v-if="company.addressLine1"
            class="mr-1"
          >
            <b-avatar
              rounded
              variant="light-primary"
              size="34"
            >
              <feather-icon
                icon="MapPinIcon"
                size="18"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body v-if="company.addressLine1">
            <h6 class="mt-0 pt-50">
              {{ company.addressLine1 }} , {{ company.addressLine2 }} , {{ company.postcode }} , {{ company.city }}  , {{ company.country }}
            </h6>
          </b-media-body>
        </b-media>
      </b-card-body>
    </b-card>
    <b-row>
      <b-col
        cols="12"
        lg="12"
        md="12"
      >
        <company-user-view
          :table-data="companyUsers"
        />
      </b-col>
      <b-col
        cols="12"
        lg="12"
        md="12"
      >
        <company-game-view
          :table-data="companyGames"
        />
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BCard, BCardBody, BCardTitle, BMedia, BMediaAside,
  BAvatar, VBTooltip, BMediaBody, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import mixinAlert from '@/constants/mixinAlert'
import { mapPackageToVariant } from '@/constants/utils'
import { mapActions } from 'vuex'
import CompanyUserView from './CompanyUserView.vue'
import CompanyGameView from './CompanyGameView.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BRow,
    BCol,
    BBadge,
    CompanyUserView,
    CompanyGameView,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinList],
  data() {
    return {
      company: {
        id: 0,
        name: '',
        phonenumber: '',
        addressLine1: '',
        addressLine2: '',
        postcode: '',
        city: '',
        country: '',
      },
      companyGames: [],
      companyUsers: [],
    }
  },
  created() {
    const { id } = router.currentRoute.params
    if (id) {
      this.fetchCompany(id)
      // this.fetchCompanyGames(id)
      // this.fetchCompanyUsers(id)
    }
  },
  methods: {
    ...mapActions('company', [
      'fetchCompanyById',
    ]),
    fetchCompany(id) {
      if (id) {
        this.fetchCompanyById(id).then(response => {
          if (response) {
            this.company = response.data
          }
        })
      }
    },
    fetchCompanyGames(id) {
      if (id) {
        store.dispatch('app-game/fetchCompanyGames',
          id)
          .then(response => {
            const { data } = response
            this.companyGames = data
          })
          .catch(() => {
            this.showErrorMessage()
          })
      }
    },
    fetchCompanyUsers(id) {
      if (id) {
        store.dispatch('app-game/fetchCompanyUsers',
          id)
          .then(response => {
            const { data } = response
            this.companyUsers = data
          })
          .catch(() => {
            this.showErrorMessage()
          })
      }
    },
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
      mapPackageToVariant,
    }
  },

}
</script>
